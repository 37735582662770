import { useEffect, useRef } from 'react';
import { Messages } from 'primereact/messages';
import { useAxios } from '../../utils/hooks.ts';

export default function MessageDisplay() {
    const messageRef = useRef(null);
    const axiosInstance = useAxios();

    useEffect(() => {
        axiosInstance.current.get("/messages").then((response) => {
        const messageList = [];
        response.data.forEach(m => {
            messageList.push({
                severity: m.type === 0 ? 'warn' : 'info', detail: m.text, sticky: true
            })
        })
        messageRef.current.show(messageList);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <Messages ref={messageRef} />
        </div>
    )
}