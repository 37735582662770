import { ProgressSpinner } from "primereact/progressspinner";

function ProgressWrapper() {
	return (
		<div className="flex-row center align-center" style={{ height: '100vh'}}>
			<ProgressSpinner />
		</div>
	)
}

export default ProgressWrapper;